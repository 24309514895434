<template>
    <div>
        <el-input
            v-model="queryInfo.query"
            clearable
            size="small"
            placeholder="输入名称或者邮箱搜索"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="getTableData()"
        />
        <span>
            <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
        </span>
        <div  class="crdc-opts">
          <span>
            <slot name="left" />
            <el-button
              class="filter-item"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="add()"
            >
              新增
            </el-button>
            <!-- <el-button
              class="filter-item"
              size="mini"
              type="success"
              icon="el-icon-edit"
            >
              修改
            </el-button>
            <el-button
              slot="reference"
              class="filter-item"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            >
              删除
            </el-button> -->
          </span>
          <!--右侧-->
            <slot name="right" />
          <el-button-group class="crud-opts-right">
              <el-button
                size="mini"
                icon="el-icon-refresh"
                @click="getTableData()"
              />
            </el-button-group>
        </div>
        <el-dialog append-to-body  title="角色" :visible.sync="addDialogVisible" width="35%" @close='resetForm'>
          <el-form ref="form"  :model="form"  label-width="80px" >
            <div>
            <el-form-item label="角色名" prop="role_NAME" >
              <el-col :span="11">
                <el-input v-model="form.role_NAME"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="是否有效" prop="is_VALID">
              <el-select v-model="form.is_VALID"  placeholder="请选择">
                <el-option
                  v-for="item in isOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="resetForm()" >取消</el-button>
            <el-button v-if="isAdd == true"  type="primary" @click="submitFrom(form)">确认</el-button>
            <el-button v-if="isAdd == false" type="primary" @click="updateFrom(form)">修改</el-button>
          </div>
        </el-dialog>
        <el-row :gutter="15">
            <!--角色管理-->
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="17" style="margin-bottom: 10px">
              <el-card>
                <el-table
                :data="tableData"  highlight-current-row
                @current-change="companyCurrentChange"
                border stripe
                style="width: 100%">
                <el-table-column
                  fixed
                  prop="role_ID"
                  label="角色ID"
                  width="150">
                </el-table-column>
                <el-table-column
                  prop="role_NAME"
                  label="角色名"
                  width="350">
                </el-table-column>
                <el-table-column
                  prop="create_TIME"
                  label="创建时间"
                  width="150" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                  label="操作" align="center"
                  width="180">
                  <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)"  ></el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteFrom(scope.$index, scope.row)"></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum"
                :page-sizes="[2, 5, 10, 15]"
                :page-size="queryInfo.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totle"
              ></el-pagination>
              </el-card>
            </el-col>
            <!-- 菜单授权 -->
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="7">
              <el-card class="box-card" shadow="never">
                <div slot="header" class="clearfix">
                  <el-tooltip class="item" effect="dark" content="选择指定角色分配菜单" placement="top">
                    <span class="role-span">菜单分配</span>
                  </el-tooltip>
                  <el-button
                    :loading="menuLoading"
                    icon="el-icon-check"
                    size="mini"
                    style="float: right; padding: 6px 9px"
                    type="primary"
                    @click="saveMenu"
                  >保存</el-button>
                </div>
                <el-tree
                  ref="menu"
                  :data="menus"
                  :default-checked-keys="menuIds"
                  :props="defaultProps"
                  accordion
                  @check="getCurrentNode"
                  check-strictly
                  show-checkbox
                  node-key="id"
                />
              </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
  name: 'Role',
  created () { //  初始化
    this.getTableData()
    this.getMenu()
  },
  methods: {
    async getMenu () {
      const { data: res } = await this.$http.get('query/menu')
      this.menus = res
    },
    async getTableData () {
      const { data: res } = await this.$http.get('query/role', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    async companyCurrentChange (val) {
      const { data: res } = await this.$http.get('query/menu/by/roleid', {
        params: val
      })
      this.$refs.menu.setCheckedKeys([])
      this.menuIds = res
      this.formMenu.role_ID = val.role_ID
    },
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    add () {
      this.queryRoleOptions()
      this.addDialogVisible = true
      this.isAdd = true
    },
    async handleEdit (index, row) {
      this.queryRoleOptions()
      this.addDialogVisible = true
      this.isAdd = false
      const { data: res } = await this.$http.get('query/role/by/id', {
        params: { role_ID: row.role_ID }
      })
      this.form = res
    },
    resetForm () {
      this.addDialogVisible = false
      this.$refs.form.resetFields()
    },
    async submitFrom () {
      await this.$http.post('insert/role', this.form)
      this.resetForm()
      this.getTableData()
    },
    async updateFrom () {
      await this.$http.post('update/role', this.form)
      this.resetForm()
      this.getTableData()
    },
    async deleteFrom (index, row) {
      // await this.$http.post('delete/banner', row)
      this.getTableData()
    },
    async queryRoleOptions (index, row) {
      const { data: res } = await this.$http.get('query/role/node')
      this.roleOptions = res
    },
    async saveMenu () {
      // console.log(this.menuIds)
      // console.log(this.$refs.menu.getCheckedNodes())
      // menu_IDS
      await this.$http.post('update/role/menu', {
        role_ID: this.formMenu.role_ID, menu_IDS: this.$refs.menu.getCheckedKeys()
      })
    },
    getCurrentNode (data) {
      const node = this.$refs.menu.getNode(data)
      this.childNodes(node)
      this.parentNodes(node)
      this.isEditorFlag = true
      this.menuIds = this.$refs.menu.getCheckedKeys()
      this.menuIdsisEditor = this.$refs.menu.getCheckedKeys().concat(this.$refs.menu.getHalfCheckedKeys())
    },
    childNodes (node) {
      const len = node.childNodes.length
      for (let i = 0; i < len; i++) {
        node.childNodes[i].checked = node.checked
        this.childNodes(node.childNodes[i])
      }
    },
    parentNodes (node) {
      if (node.parent) {
        for (const key in node) {
          if (key === 'parent') {
            node[key].checked = true
            this.parentNodes(node[key])
          }
        }
      }
    }
  },
  data () {
    return {
      form: {
        role_ID: '',
        role_NAME: '',
        is_VALID: ''
      },
      formMenu: {
        role_ID: ''
      },
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10
      },
      totle: 0,
      tableData: [],
      isAdd: '',
      addDialogVisible: false,
      isOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      roleOptions: [],
      sexOptions: [{
        value: '0',
        label: '女'
      }, {
        value: '1',
        label: '男'
      }],
      value1: ['1', '2'],
      menus: [{ children: [{ id: 14, label: '邮件工具' }, { id: 15, label: '邮件工具5' }], id: 36, label: '系统工具' }],
      menuLoading: false,
      menuIds: [],
      isEditorFlag: '',
      menuIdsisEditor: '',
      defaultProps: { children: 'children', label: 'label' }
    }
  }
}
</script>
<style >
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
</style>
